import OfficeMapLocator from './office_map_locator.js.erb';
import OfficePhotosUploader from './office_photos_uploader';
import OfficeRemover from './office_remover';
import OnlineOfficeTherapyHandler from './online_office_therapy_handler';
import PhoneInput from './phone_input';

window.officePhotosUploaderLoaded = false;

export default class OfficesHandler {
  constructor() {
    new OfficeRemover();
    new OfficeMapLocator();
    new OnlineOfficeTherapyHandler();
    new PhoneInput();

    this.handleAddNewOfficeBtn();

    if (!window.officePhotosUploaderLoaded) {
      new OfficePhotosUploader();
      window.officePhotosUploaderLoaded = true;
    }
  }

  handleAddNewOfficeBtn() {
    $('.profile-form-wrapper').on('cocoon:after-insert', function(e, officeAdded) {
      new PhoneInput(officeAdded.find('.input-phone')[0]);
    });
  }
}
