export default class OfficeRemover {
  constructor() {
    this.initializeUnsavedOfficeRemover();
    this.removeDeleteOfficeButtonWhenThereIsOnlyOneOffice();
  }

  initializeUnsavedOfficeRemover() {
    let formWrapper = document.querySelector('.profile-form-wrapper');

    formWrapper.addEventListener('click', function(evt) {
      let removeOffice = evt.target.closest('a.delete-office');

      if (removeOffice && removeOffice.classList.contains('remove-office')) {
        evt.preventDefault();

        let officeFields = removeOffice.parentNode;
        let officeFieldsParent = officeFields.parentNode;

        officeFieldsParent.removeChild(officeFields);
      }
    });
  }

  removeDeleteOfficeButtonWhenThereIsOnlyOneOffice() {
    let form = document.querySelector('.profile-form-wrapper form');
    let officesFields = form.querySelectorAll('.office-fields');

    if (officesFields.length == 1) {
      let removeOfficeButton = officesFields[0].querySelector('.delete-office');
      officesFields[0].removeChild(removeOfficeButton);
    }
  }
}
