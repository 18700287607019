export default class MobileNavHandler {
  constructor() {
    this.mobileMenu = document.getElementById('mobile-menu');
    this.openMobileMenuBtn = document.querySelector('nav.navbar .navbar-toggler');
    this.closeMobileMenuBtn = document.querySelector('#mobile-menu .close-mobile-nav');

    this.initializeOpenMobileMenu();
    this.initializeCloseMobileMenu();
  }

  initializeOpenMobileMenu() {
    this.openMobileMenuBtn?.addEventListener('click', function() {
      this.mobileMenu.classList.add('is-showing');
    }.bind(this));
  }

  initializeCloseMobileMenu() {
    this.closeMobileMenuBtn?.addEventListener('click', function() {
      this.mobileMenu.classList.remove('is-showing');
    }.bind(this));
  }
}
