export default class PopoversHandler {
  constructor() {
    this.initPopovers();
  }

  initPopovers() {
    $('[data-toggle=popover], [data-toggle=popover-html]').each((_, el) => {
      const tooltip = $(el).popover({ html: el.matches('[data-toggle=popover-html], [data-html]') });

      tooltip
        .on('mouseenter', function () {
          $(this).popover('show');
          $('.popover').on('mouseleave', () => {
            $(this).popover('hide');
          });
        })
        .on('mouseleave', function () {
          if ($('.popover:hover').length) return;

          $(this).popover('hide');
        });
    });
  }
}
