export default class PreferNotToSayHandler {
  constructor(resourceName) {
    this.resourceName = resourceName;
    this.handleGendersPreferNotToSay();
    this.handleSexualOrientationsPreferNotToSay();
    this.handleRaceAndEthnicitiesPreferNotToSay();
    this.handleReligionsPreferNotToSay();
  }

  handleGendersPreferNotToSay() {
    let checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${this.resourceName}[genders][]"]`);
    let preferNotToSayGender = document.getElementById(`${this.resourceName}_genders_prefer_not_to_say`);

    preferNotToSayGender.addEventListener('change', function() {
      if (this.checked) {
        for(let chckbx of checkboxes) {
          chckbx.checked = false;
          chckbx.disabled = true;
        }

        this.checked = true;
        this.disabled = false;
      } else {
        for(let chckbx of checkboxes) {
          chckbx.disabled = false;
        }
      }
    });
  }

  handleSexualOrientationsPreferNotToSay() {
    let checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${this.resourceName}[sexual_orientations][]"]`);
    let preferNotToSaySexualOrientation = document.getElementById(`${this.resourceName}_sexual_orientations_prefer_not_to_say`);

    preferNotToSaySexualOrientation.addEventListener('change', function() {
      if (this.checked) {
        for(let chckbx of checkboxes) {
          chckbx.checked = false;
          chckbx.disabled = true;
        }

        this.checked = true;
        this.disabled = false;
      } else {
        for(let chckbx of checkboxes) {
          chckbx.disabled = false;
        }
      }
    });
  }

  handleRaceAndEthnicitiesPreferNotToSay() {
    let checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${this.resourceName}[race_and_ethnicity][]"]`);
    let preferNotToSayRaceAndEthnicity = document.getElementById(`${this.resourceName}_race_and_ethnicity_prefer_not_to_say`);

    preferNotToSayRaceAndEthnicity.addEventListener('change', function() {
      if (this.checked) {
        for(let chckbx of checkboxes) {
          chckbx.checked = false;
          chckbx.disabled = true;
        }

        this.checked = true;
        this.disabled = false;
      } else {
        for(let chckbx of checkboxes) {
          chckbx.disabled = false;
        }
      }
    });
  }

  handleReligionsPreferNotToSay() {
    let checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${this.resourceName}[religion][]"]`);
    let preferNotToSayReligion = document.getElementById(`${this.resourceName}_religion_prefer_not_to_say`);

    preferNotToSayReligion.addEventListener('change', function() {
      if (this.checked) {
        for(let chckbx of checkboxes) {
          chckbx.checked = false;
          chckbx.disabled = true;
        }

        this.checked = true;
        this.disabled = false;
      } else {
        for(let chckbx of checkboxes) {
          chckbx.disabled = false;
        }
      }
    });
  }
}
