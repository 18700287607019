import autocomplete from 'autocompleter';
import Rails from 'rails-ujs';

export default class Reviews {
  constructor() {
    this.initializePositiveRadioBtns();
    this.initializeTherapistsTypeahead();
    this.initializeAttendedMeetingRadioBtns();
  }

  initializeAttendedMeetingRadioBtns() {
    let attendedMeetingRadioBtns = document.querySelectorAll('input[name=\'review[attended_meeting]\']');
    let ongoingTreatmentRadioBtns = document.querySelectorAll('input[name=\'review[ongoing_treatment]\']');
    let inputTypeahead = document.querySelector('.input-therapists-typeahead .form-control');
    let inputGroupPrepend = document.querySelector('.input-therapists-typeahead .input-group-prepend');

    for (let attendedMeetingRadioBtn of attendedMeetingRadioBtns) {
      if (attendedMeetingRadioBtn.id == 'review_attended_meeting_false' && attendedMeetingRadioBtn.checked) {
        inputGroupPrepend.classList.add('disabled');
        inputTypeahead.disabled = true;

        for (let ongoingTreatmentRadioBtn of ongoingTreatmentRadioBtns) {
          ongoingTreatmentRadioBtn.disabled = true;
        }
      }

      attendedMeetingRadioBtn.addEventListener('change', function(evt) {
        if (evt.target.value == 'false') {
          inputGroupPrepend.classList.add('disabled');
          inputTypeahead.disabled = true;

          for (let ongoingTreatmentRadioBtn of ongoingTreatmentRadioBtns) {
            ongoingTreatmentRadioBtn.disabled = true;
          }
        } else {
          inputGroupPrepend.classList.remove('disabled');
          inputTypeahead.disabled = false;

          for (let ongoingTreatmentRadioBtn of ongoingTreatmentRadioBtns) {
            ongoingTreatmentRadioBtn.disabled = false;
          }
        }
      });
    }
  }

  initializePositiveRadioBtns() {
    let positiveBtn = document.querySelector('.btn-positive');
    let negativeBtn = document.querySelector('.btn-negative');
    let positiveRadioBtn = document.querySelector('.btn-positive input');
    let negativeRadioBtn = document.querySelector('.btn-negative input');

    positiveRadioBtn.addEventListener('change', function() {
      if(this.checked) {
        positiveBtn.classList.add('active');
        negativeBtn.classList.remove('active');
      }
    });

    negativeRadioBtn.addEventListener('change', function() {
      if(this.checked) {
        negativeBtn.classList.add('active');
        positiveBtn.classList.remove('active');
      }
    });
  }

  initializeTherapistsTypeahead() {
    let inputTypeahead = document.querySelector('.input-therapists-typeahead .form-control');
    let hiddenInput = document.querySelector('.input-therapists-typeahead input[type="hidden"]');

    autocomplete({
      input: inputTypeahead,
      className: inputTypeahead.dataset.autocompleterClass,
      fetch: function(text, update) {
        text = text.toLowerCase();

        if (text.length < 3) {
          return null;
        }

        Rails.ajax({
          type: 'GET',
          url: `/providers?q=${text}`,
          success: function(response) {
            update(response);
          },
          error: function() {
            update([]);
          }
        });
      },
      onSelect: function(item) {
        inputTypeahead.value = item.label;
        hiddenInput.value = item.value;
      },
      customize: function(input, inputRect, container) {
        let parentEl = input.parentNode;
        let parentRect = parentEl.getBoundingClientRect();

        container.style.left = parentRect.left + 'px';
        container.style.width = parentRect.width + 'px';
      }
    });
  }
}
