export default class PromoteMyPractice {
  constructor() {
    this.initializePreviewRadios();
    this.initializeCopyButton();
  }

  initializePreviewRadios() {
    let previewContainer = document.querySelector('.settings.promoting .preview');
    let previewRadios = document.querySelectorAll('.settings.promoting input[type="radio"]');

    for(let radio of previewRadios) {
      radio.addEventListener('change', (e) => {
        previewContainer.classList.remove('light', 'dark');
        previewContainer.classList.add(e.target.value);
      });
    }
  }

  initializeCopyButton() {
    let codeInput = document.querySelector('.settings.promoting textarea');
    let copyBtn = document.querySelector('.settings.promoting .btn-copy');

    copyBtn.addEventListener('click', () => {
      navigator.clipboard.writeText(codeInput.value);
    });
  }
}
