import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import autocomplete from 'autocompleter';

export default class LocationAutocompleter {
  static get SUPPORTED_COUNTRIES() {
    return ['US', 'CA', 'GB', 'CN', 'HK'];
  }

  constructor() {
    const mapboxToken = 'pk.eyJ1IjoidGhlc2hyaW5rc3BhY2UiLCJhIjoiY2pveXVlcXljMmpmNDN4bWxxdXU2ZTF5bSJ9.1fSUfOi71bu24kUsjnd6Pg';
    if (!mapboxToken) return;

    const mapboxClient = mbxGeocoding({ accessToken: mapboxToken });
    this.selectedOptionFromAutocomplete = false;
    this.initializeAutocompleters(mapboxClient);
  }

  getHiddenCoordsInputs(locationInput) {
    let formContainer = document.querySelector(locationInput.dataset.targetFormClass);
    let latitudeInput = formContainer.querySelector('input[name="search[latitude]"]');
    let longitudeInput = formContainer.querySelector('input[name="search[longitude]"]');
    let stateInput = formContainer.querySelector('input[name="search[state]"]');

    return { latitudeInput, longitudeInput, stateInput };
  }

  submitSearchForm(locationInput) {
    const formContainer = document.querySelector(locationInput.dataset.targetFormClass);
    const submitButton = formContainer.querySelector('button');

    submitButton.click();
  }

  initializeAutocompleters(mapboxClient) {
    let locationInputs = document.querySelectorAll('.location-typeahead');

    for (let locationInput of locationInputs) {
      let suggestionsDiv = locationInput.parentElement.querySelector('.current-location-suggestion');

      autocomplete({
        input: locationInput,
        className: locationInput.dataset.autocompleterClass,
        minLength: 3,
        debounceWaitMs: 200,
        fetch: (text, update) => {
          mapboxClient
            .forwardGeocode({
              query: text,
              autocomplete: true,
              types: ['place'],
              countries: LocationAutocompleter.SUPPORTED_COUNTRIES
            })
            .send()
            .then((response) => {
              if (response.body.features) {
                let suggestions = response.body.features.map(
                  (match) => new Object({ label: match.place_name, value: match.center })
                );
                suggestions.unshift(new Object({ label: 'Current Location', value: [], forCurrentLocation: true }));
                update(suggestions);
              }
            });
        },
        onSelect: (item) => {
          if (item.forCurrentLocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                let { latitudeInput, longitudeInput } = this.getHiddenCoordsInputs(locationInput);

                latitudeInput.value = position.coords.latitude;
                longitudeInput.value = position.coords.longitude;
                locationInput.value = item.label;

                this.submitSearchForm(locationInput);
              },
              (error) => {
                if (error.code === 2 || error.code === 3) console.error('Device failed to lock position');
              }
            );
          } else {
            let { latitudeInput, longitudeInput, stateInput } = this.getHiddenCoordsInputs(locationInput);

            latitudeInput.value = item.value[1];
            longitudeInput.value = item.value[0];
            locationInput.value = item.label;
            stateInput.value = item.label.split(', ')[1];
          }

          this.selectedOptionFromAutocomplete = true;
        },
        render: (item, currentValue) => {
          let suggestionDiv = document.createElement('div');

          if (navigator.geolocation && item.forCurrentLocation) {
            suggestionDiv.innerHTML = suggestionsDiv.innerHTML;
          } else {
            suggestionDiv.textContent = item.label;
          }

          return suggestionDiv;
        }
      });

      locationInput.addEventListener(
        'input',
        function (evt) {
          if (!this.selectedOptionFromAutocomplete) {
            let { latitudeInput, longitudeInput, stateInput } = this.getHiddenCoordsInputs(locationInput);

            latitudeInput.value = null;
            longitudeInput.value = null;
            stateInput.value = null;
          }

          this.selectedOptionFromAutocomplete = false;
        }.bind(this)
      );
    }
  }
}
