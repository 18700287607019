import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';

import 'jquery';
import 'popper.js';
import 'bootstrap';
import "/var/www/theshrinkspace/repos/theshrinkspace/vendor/bundle/ruby/3.1.0/gems/cocoon-1.2.15/app/assets/javascripts/cocoon";

import 'es6-promise/auto';
import ComponentsLoader from './components/loader';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.tr-clickable').forEach((tr) =>
    tr.addEventListener('click', (ev) => {
      ev.target.querySelector('a')?.click();
    })
  );
});

document.addEventListener('turbolinks:load', () => {
  new ComponentsLoader();
  if (!document.location.hash) window.scrollTo(0, 0);
});
