export default class ErroredFormsHandler {
  constructor() {
    this.initializeErrorsHandler();
  }

  initializeErrorsHandler() {
    let erroredInputs = document.querySelectorAll('.field_with_errors input, .field_with_errors textarea, .field_with_errors select');

    erroredInputs[0]?.focus();
    for (let erroredInput of erroredInputs) {
      erroredInput.addEventListener('change', function() {
        this.parentNode.classList.remove('field_with_errors');

        if (this.type == 'checkbox' || this.type == 'radio') {
          if (this.parentNode.parentNode.parentNode.querySelector('.error-message')) {
            this.parentNode.parentNode.parentNode.querySelector('.error-message').remove();
          }
        } else {
          if (this.parentNode.parentNode.querySelector('.error-message')) {
            this.parentNode.parentNode.querySelector('.error-message').remove();
          }
        }
      });
    }
  }
}
