export default class LicensesHandler {
  constructor() {
    this.initializeNewLicenseAddition();
    this.initializeUnsavedLicensureRemover();
    this.removeDeleteLicenseButtonWhenThereIsOnlyOneLicense();
    this.initializeAssociateLicenseCheckHandler();
    this.initializePsypactCheckDisplayer();
    this.initializeLicenseCodeDisplayer();
    this.initializeMedicalInstitutionDisplayer();
    this.initializeOrganizationAffiliationDisplayer();
    this.initializeAccreditationBodyDisplayer();
  }

  initializeNewLicenseAddition() {
    let self = this;

    $('.profile-form-wrapper').on('cocoon:after-insert', function() {
      self.initializeAssociateLicenseCheckHandler();
      self.initializePsypactCheckDisplayer();
      self.initializeMedicalInstitutionDisplayer();
    });
  }

  initializeUnsavedLicensureRemover() {
    let formWrapper = document.querySelector('.profile-form-wrapper');

    formWrapper.addEventListener('click', function(evt) {
      let removeLicense = evt.target.closest('a.delete-license');

      if (removeLicense && removeLicense.classList.contains('remove-license')) {
        evt.preventDefault();

        let licenseFields = removeLicense.parentNode;
        let licenseFieldsParent = licenseFields.parentNode;

        if (licenseFieldsParent.querySelectorAll('.license-fields').length == 1) {
          return null;
        }

        licenseFieldsParent.removeChild(licenseFields);
      }
    });
  }

  removeDeleteLicenseButtonWhenThereIsOnlyOneLicense() {
    let form = document.querySelector('.profile-form-wrapper form');
    let licensesFields = form.querySelectorAll('.license-fields');

    if (licensesFields.length == 1) {
      let removeLicenseButton = licensesFields[0].querySelector('.delete-license');
      licensesFields[0].removeChild(removeLicenseButton);
    }
  }

  initializeAssociateLicenseCheckHandler() {
    let associateLicenseChecks = document.querySelectorAll('.associate-license input[type="checkbox"]');

    for (let associateLicenseCheck of associateLicenseChecks) {
      associateLicenseCheck.addEventListener('change', function(e) {
        let supervisorCheckContainer = e.target.parentNode.parentNode.querySelector('.supervisor-license');

        if (supervisorCheckContainer === null) { return; }

        if (e.target.checked) {
          supervisorCheckContainer.classList.remove('d-none');
        } else {
          supervisorCheckContainer.classList.add('d-none');
        }
      });
    }
  }

  initializePsypactCheckDisplayer() {
    let therapistTypeSelects = document.querySelectorAll('.therapist-type-select');

    for (let therapistTypeSelect of therapistTypeSelects) {
      therapistTypeSelect.addEventListener('change', function(e) {
        let psypactCheckWrapper = e.target.parentNode.parentNode.querySelector('.psypact-license');

        if (psypactCheckWrapper === null) { return; }

        let psypackCheck = psypactCheckWrapper.querySelector('input[type="checkbox"]');

        if (e.target.value == 'Psychologist') {
          psypactCheckWrapper.classList.remove('d-none');
        } else {
          psypactCheckWrapper.classList.add('d-none');
          psypackCheck.checked = false;
        }
      });
    }
  }

  initializeLicenseCodeDisplayer() {
    let therapistTypeSelects = document.querySelectorAll('.therapist-type-select');
    let stateSelects = document.querySelectorAll('.state-select');

    if (stateSelects.length == 0) { return; }

    for (let therapistTypeSelect of therapistTypeSelects) {
      therapistTypeSelect.addEventListener('change', function(e) {
        let licenseCodeWrapper = e.target.parentNode.parentNode.querySelector('.license-code');
        let licenseCode = licenseCodeWrapper.querySelector('input[type="text"]');
        let stateSelect = e.target.parentNode.parentNode.querySelector('.state-select');

        if (stateSelect.value == 'pr' && ['Psychologist', 'Psychiatrist'].includes(e.target.value)) {
          licenseCodeWrapper.classList.remove('d-none');
        } else {
          licenseCodeWrapper.classList.add('d-none');
          licenseCode.value = null;
        }
      });
    }

    for (let stateSelect of stateSelects) {
      stateSelect.addEventListener('change', function(e) {
        let licenseCodeWrapper = e.target.parentNode.parentNode.querySelector('.license-code');
        let licenseCode = licenseCodeWrapper.querySelector('input[type="text"]');
        let therapistTypeSelect = e.target.parentNode.parentNode.querySelector('.therapist-type-select');

        if (e.target.value == 'pr' && ['Psychologist', 'Psychiatrist'].includes(therapistTypeSelect.value)) {
          licenseCodeWrapper.classList.remove('d-none');
        } else {
          licenseCodeWrapper.classList.add('d-none');
          licenseCode.value = null;
        }
      });
    }
  }

  initializeMedicalInstitutionDisplayer() {
    let therapistTypeSelects = document.querySelectorAll('.therapist-type-select');

    for (let therapistTypeSelect of therapistTypeSelects) {
      therapistTypeSelect.addEventListener('change', function(e) {
        let medicalInstitutionWrapper = e.target.parentNode.parentNode.querySelector('.medical-institution');

        if (medicalInstitutionWrapper === null) { return; }

        let medicalInstitutionInput = medicalInstitutionWrapper.querySelector('input[type="text"]');

        if (e.target.value == 'Psychiatrist') {
          medicalInstitutionWrapper.classList.remove('d-none');
        } else {
          medicalInstitutionWrapper.classList.add('d-none');
          medicalInstitutionInput.value = '';
        }
      });
    }
  }

  initializeOrganizationAffiliationDisplayer() {
    let therapistTypeSelects = document.querySelectorAll('.therapist-type-select');

    for (let therapistTypeSelect of therapistTypeSelects) {
      therapistTypeSelect.addEventListener('change', function(e) {
        let organizationAffiliationWrapper = e.target.parentNode.parentNode.querySelector('.organization-affiliation');

        if (organizationAffiliationWrapper === null) { return; }

        let organizationAffiliationSelect = organizationAffiliationWrapper.querySelector('select');

        if (e.target.value == 'Clinical Psychologist') {
          organizationAffiliationWrapper.classList.remove('d-none');
        } else {
          organizationAffiliationWrapper.classList.add('d-none');
          organizationAffiliationSelect.value = '';
        }
      });
    }
  }

  initializeAccreditationBodyDisplayer() {
    let therapistTypeSelects = document.querySelectorAll('.therapist-type-select');

    for (let therapistTypeSelect of therapistTypeSelects) {
      therapistTypeSelect.addEventListener('change', function(e) {
        let accreditationBodyWrapper = e.target.parentNode.parentNode.querySelector('.accreditation-body-wrapper');

        if (accreditationBodyWrapper === null) { return; }

        let accreditationBodyInput = accreditationBodyWrapper.querySelector('select');

        if (e.target.value == 'Psychological Counselor') {
          accreditationBodyWrapper.classList.remove('d-none');
        } else {
          accreditationBodyWrapper.classList.add('d-none');
          accreditationBodyInput.value = '';
        }
      });
    }
  }
}
