import Glide from '@glidejs/glide';
import mapboxgl from '!mapbox-gl';

export default class TherapistProfile {
  constructor() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidGhlc2hyaW5rc3BhY2UiLCJhIjoiY2pveXVlcXljMmpmNDN4bWxxdXU2ZTF5bSJ9.1fSUfOi71bu24kUsjnd6Pg';

    this.initializeReadMoreLink('.self-introduction');
    this.initializeReadMoreLink('.diversity-incorporation');
    this.initializeReadMoreLink('.insurance-eligibility-checker', '.insurance-carriers');
    document.querySelectorAll('[data-js-readmore-container]').forEach((element) => {
      this.initializeReadMoreLink(element, '[data-js-readmore-hidden]');
    });
    this.initializeOfficePhotosSliders();
    this.initializeOfficeMaps();
    this.initializeVideoHandler();
    this.initializePrivateNotesViewAll();
    this.initializeStudentReviewsViewAll();
    this.printProfile();
  }

  initializeReadMoreLink(parent, hiddenElement = 'p') {
    if (!parent.nodeType) parent = document.querySelector(parent);
    if (!parent) return;
    let readMoreLink = parent.querySelector('.read-more, [data-js-readmore]');
    let paragraph = parent.querySelector(hiddenElement);

    if (readMoreLink == null) return;

    if (paragraph.scrollHeight <= paragraph.offsetHeight) {
      readMoreLink.parentNode.removeChild(readMoreLink);
      paragraph.style.height = 'auto';
    }

    readMoreLink.addEventListener('click', function (e) {
      e.preventDefault();
      paragraph.style.height = 'auto';
      readMoreLink.parentNode.removeChild(readMoreLink);
    });
  }

  initializePrivateNotesViewAll() {
    let viewAllButton = document.querySelector('[data-js-private-notes-view-all], .private-notes-view-all button');
    if (!viewAllButton) return;
    let notesContainer = document.querySelector(
      '[data-js-private-notes-container], .private-notes-section .section-content'
    );

    if (notesContainer.scrollHeight <= notesContainer.offsetHeight) {
      viewAllButton.parentNode.remove();
      notesContainer.style.height = 'auto';
    } else {
      viewAllButton.addEventListener('click', function (e) {
        e.preventDefault();
        notesContainer.style.height = 'auto';
        viewAllButton.parentNode.remove();
      });
    }
  }

  initializeStudentReviewsViewAll() {
    let studentReviewsViewAllButton = document.querySelector(
      '[data-js-student-reviews-view-all], .student-reviews-view-all button'
    );

    if (!studentReviewsViewAllButton) return;

    let studentReviews = document.querySelectorAll('[data-js-student-review], .reviews-container .review');

    studentReviewsViewAllButton.addEventListener('click', () => {
      for (let studentReview of studentReviews) {
        studentReview.classList.remove('d-none');
      }

      studentReviewsViewAllButton.classList.add('d-none');
    });
  }

  initializeOfficePhotosSliders() {
    let photoGalleries = document.querySelectorAll('.glide');

    for (let gallery of photoGalleries) {
      let glide = new Glide(`#${gallery.id}`, {
        type: 'carousel'
      });

      // Next 2 functions helps to recalculate height of carousel on slide change (next|prev)
      glide.on('mount', function () {
        let slideHeight = $('.glide__slide--active').outerHeight();
        let glideTrack = $('.glide__track').outerHeight();
        if (slideHeight != glideTrack) {
          let newHeight = slideHeight;
          $('.glide__track').css('height', newHeight);
        }
      });

      glide.on('run.after', function () {
        let slideHeight = $('.glide__slide--active').outerHeight();
        let glideTrack = $('.glide__track').outerHeight();
        if (slideHeight != glideTrack) {
          let newHeight = slideHeight;
          $('.glide__track').css('height', newHeight);
        }
      });

      glide.mount();

      // Could not find a way to recalculate height at initialization, so the fix is to manually change slides (rewind) so recalculation happens.
      glide.go('<<');
    }
  }

  initializeOfficeMaps() {
    if (!mapboxgl.accessToken) return;

    let mapContainers = document.querySelectorAll('.offices .office-location');
    let mapMarker = document.querySelector('[data-js-office-map-marker] svg, .office-map-marker svg');

    mapContainers.forEach((mapContainer) => {
      let latitude = parseFloat(mapContainer.dataset.latitude);
      let longitude = parseFloat(mapContainer.dataset.longitude);

      let map = new mapboxgl.Map({
        container: mapContainer.id,
        center: [longitude, latitude],
        interactive: true,
        zoom: 15,
        style: 'mapbox://styles/mapbox/streets-v9'
      });

      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false
        })
      );

      new mapboxgl.Marker({ element: mapMarker.cloneNode(true) }).setLngLat([longitude, latitude]).addTo(map);
    });
  }

  initializeVideoHandler() {
    document.querySelectorAll('[ data-js-video-link], #open-therapist-video').forEach((openVideoCaller) => {
      openVideoCaller.addEventListener('click', (ev) => ev.preventDefault());

      let modalContainer = document.querySelector('#therapist-video-modal .modal-content');
      let videoUrl = new URL(openVideoCaller.dataset.videoUrl);
      let videoID,
        htmlIframeVideo = null;

      if (videoUrl.host.match(/youtube/)) {
        videoID = videoUrl.searchParams.get('v');
        htmlIframeVideo = `
        <button type="button" id="close-home-video" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <iframe width="640" height="360" title="provider profile video" src="https://www.youtube.com/embed/${videoID}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      } else {
        videoID = videoUrl.pathname.slice(1);
        htmlIframeVideo = `
          <button type="button" id="close-home-video" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <iframe class="embed-responsive-item" title="provider profile video" src="https://player.vimeo.com/video/${videoID}?autoplay=1&loop=1&autopause=0" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen />`;
      }

      openVideoCaller.addEventListener('click', () => {
        modalContainer.innerHTML = htmlIframeVideo;

        $('#therapist-video-modal').modal('show');
        $('#therapist-video-modal').on('hidden.bs.modal', () => {
          modalContainer.innerHTML = '';
        });
      });
    });
  }

  printProfile() {
    let params = new URL(document.location).searchParams;
    let print = params.get('print');

    if (print === 'true') {
      window.print();
    }
  }
}
