import maskInput from 'vanilla-text-mask';
import select2 from 'select2'; // eslint-disable-line no-unused-vars

export default class InsuranceChecker {
  constructor() {
    this.initializeBirthdateField();
    this.initializePayersSelect();
  }

  initializeBirthdateField() {
    let birthdateInput = document.querySelector('.insurance-eligibility-checker .input-birthdate');

    if (birthdateInput == null) {
      return;
    }

    const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

    maskInput({
      inputElement: birthdateInput,
      mask: dateMask,
      guide: false
    });
  }

  initializePayersSelect() {
    $('.chosen-select').select2();
  }
}
