import ImageOrientationReseter from './image_orientation_reseter';

export default class AvatarUploader {
  constructor() {
    this.initializeNewAvatarUploader();
    this.initializeEditAvatarUploader();
  }

  initializeNewAvatarUploader() {
    let fileInput = document.querySelector('.avatar-file-new-input');
    if (fileInput === null) { return; }

    let avatarPreviewContainer = document.querySelector('.file-area');
    let avatarPreviewMessage = document.querySelector('.file-area .message');
    let imgReader = new FileReader();

    let oldBackgroundImageValue = avatarPreviewContainer.style['background-image'];
    let oldBackgroundSizeValue = avatarPreviewContainer.style['background-size'];
    let oldBorderColor = avatarPreviewContainer.style['border-color'];

    fileInput.addEventListener('change', function() {
      if (this.files.length > 0) {
        let file = this.files[0];

        ImageOrientationReseter.getOrientation(file, function(orientation) {
          imgReader.onloadend = function() {
            if (orientation > 0) {
              ImageOrientationReseter.call(imgReader.result, orientation, function(fixedImage) {
                avatarPreviewContainer.style['background-image'] = `url(${fixedImage})`;
              });
            } else {
              avatarPreviewContainer.style['background-image'] = `url(${imgReader.result})`;
            }

            if (avatarPreviewContainer.classList.contains('logo-uploader')) {
              avatarPreviewContainer.style['background-size'] = 'contain';
              avatarPreviewContainer.style['background-color'] = 'transparent';
            } else {
              avatarPreviewContainer.style['background-size'] = 'cover';
            }

            avatarPreviewContainer.style['border-color'] = 'transparent';
            avatarPreviewMessage.style['display'] = 'none';
          };

          imgReader.readAsDataURL(file);
        });
      } else {
        avatarPreviewContainer.style['background-image'] = oldBackgroundImageValue;
        avatarPreviewContainer.style['background-size'] = oldBackgroundSizeValue;
        avatarPreviewContainer.style['border-color'] = oldBorderColor;
        avatarPreviewMessage.style['display'] = 'block';
      }
    });
  }

  initializeEditAvatarUploader() {
    let fileInput = document.querySelector('.avatar-file-edit-input');
    if (fileInput === null) { return; }

    let avatarPreviewImg = document.querySelector('.update-avatar img');
    let imgReader = new FileReader();

    let oldImageValue = avatarPreviewImg.src;

    fileInput.addEventListener('change', function() {
      if (this.files.length > 0) {
        let file = this.files[0];

        ImageOrientationReseter.getOrientation(file, function(orientation) {
          imgReader.onloadend = function() {
            if (orientation > 0) {
              ImageOrientationReseter.call(imgReader.result, orientation, function(fixedImage) {
                avatarPreviewImg.src = fixedImage;
              });
            } else {
              avatarPreviewImg.src = imgReader.result;
            }

            avatarPreviewImg.maxHeight = '220';
          };

          imgReader.readAsDataURL(file);
        });
      } else {
        avatarPreviewImg.src = oldImageValue;
      }
    });
  }
}
