export default class ListUniversityOrganizationProviders {
  constructor() {
    this.initializeSelectAll();
    this.initializeInputSearch();
  }

  initializeSelectAll() {
    let selectAllLink = document.querySelector('table th a.select-all');
    let checkboxes = document.querySelectorAll('table input[type="checkbox"]');
    let shouldSelect = true;

    if (selectAllLink == null) {
      return;
    }

    selectAllLink.addEventListener('click', function(evt) {
      evt.preventDefault();

      for(let checkbox of checkboxes) {
        if (shouldSelect) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      }

      if (shouldSelect) {
        selectAllLink.text = 'Deselect All';
      } else {
        selectAllLink.text = 'Select All';
      }

      shouldSelect = !shouldSelect;
    });
  }

  initializeInputSearch() {
    let inputSearch = document.querySelector('.search-provider input[type="search"]');
    let currentSearchParams = new URLSearchParams(window.location.search);
    let typingTimer;
    let doneTypingInterval = 750;

    inputSearch.addEventListener('keyup', (evt) => {
      clearTimeout(typingTimer);

      if (evt.target.value) {
        typingTimer = setTimeout(() => {
          currentSearchParams.set('q', evt.target.value);
          window.location.search = currentSearchParams.toString();
        }, doneTypingInterval);
      } else if(currentSearchParams.has('q')){
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });

    inputSearch.addEventListener('change', (evt) => {
      if (!evt.target.value && currentSearchParams.has('q')) {
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });

    inputSearch.addEventListener('search', (evt) => {
      if (!evt.target.value && currentSearchParams.has('q')) {
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });
  }
}
