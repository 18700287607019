import PhoneInput from './phone_input';
import Rails from 'rails-ujs';

export default class ContactTherapistForm {
  constructor() {
    new PhoneInput();

    this.initializeCallProviderLinkTracker();
  }

  initializeCallProviderLinkTracker() {
    document.querySelectorAll('.contact-therapist-form .call-provider, [data-js-track-call]').forEach((callLink) => {
      callLink.addEventListener('click', (e) => {
        const therapistId = e.currentTarget.dataset.therapist || e.target.dataset.therapist;
        const therapistSlug = e.currentTarget.dataset.slug || e.target.dataset.slug;

        Rails.ajax({
          type: 'POST',
          url: `/therapists/${therapistId}/${therapistSlug}/track-call`
        });
      });
    });
  }
}
