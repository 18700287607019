import select2 from 'select2'; // eslint-disable-line no-unused-vars

const disableNonSSOFields = (val) => {
  const form = document.querySelector('#student-form');

  if (form) {
    form.querySelectorAll('input, select').forEach((input) => {
      if (input.closest('.university-student-radios, .default-university')) return;
      input.disabled = val;
    });
  }
};

export default class SignUpForm {
  constructor() {
    this.initializeUniversityStudentRadiosHandler();
    this.initializeUniversitySelect();
    this.initializeProviderProfileTypeRadios();
  }

  initializeUniversityStudentRadiosHandler() {
    let universityStudentRadios = document.querySelectorAll('.university-student-radios input[type="radio"]');
    let universityNameWrapper = document.querySelector('.default-university');

    for (let radio of universityStudentRadios) {
      radio.addEventListener('change', function (e) {
        const universityStudent = e.target.value == 'true';

        universityNameWrapper.classList[universityStudent ? 'remove' : 'add']('d-none');
        universityNameWrapper.querySelector('select').disabled = !universityStudent;

        if (!universityStudent) {
          disableNonSSOFields(universityStudent);
          $('.chosen-select').val(null).trigger('change');
          document.querySelector('.sso-prompt').classList.add('d-none');
        }
      });
    }
  }

  initializeUniversitySelect() {
    $('.chosen-select').select2();
    $('.chosen-select').on('select2:select', (ev) => {
      const target = ev.currentTarget;
      const option = target.options[target.selectedIndex];
      const universityName = option.textContent;
      const ssoUrl = option.dataset.ssoUrl;
      const ssoPrompt = document.querySelector('.sso-prompt');

      ssoPrompt.innerHTML = `<a href="${ssoUrl}">Click here to sign up with your ${universityName} University account</a>`;
      ssoPrompt.classList[option.dataset.ssoUrl ? 'remove' : 'add']('d-none');
      disableNonSSOFields(Boolean(option.dataset.ssoUrl));
    });
  }

  initializeProviderProfileTypeRadios() {
    let individualProfileRadioBtn = document.querySelector('#therapist_account_im_group_practice_0');
    let groupPracticeProfileRadioBtn = document.querySelector('#therapist_account_im_group_practice_1');
    let fullnameLabel = document.querySelector('label[for="therapist_account_fullname"]');
    let fullnameInput = document.querySelector('#therapist_account_fullname');

    if (individualProfileRadioBtn == null) {
      return;
    }

    individualProfileRadioBtn.addEventListener('change', function () {
      fullnameLabel.textContent = 'Full Name';
      fullnameInput.placeholder = 'Jane Doe';
    });

    groupPracticeProfileRadioBtn.addEventListener('change', function () {
      fullnameLabel.textContent = 'Group Practice Name';
      fullnameInput.placeholder = 'Insight Center';
    });
  }
}
