export default class OnlineOfficeTherapyHandler {
  constructor() {
    this.initializeOnlineTherapyOnlyCheckbox();
  }

  initializeOnlineTherapyOnlyCheckbox() {
    let onlineTherapyCheck = document.querySelector('.online-therapy-only input[type="checkbox"]');

    if (onlineTherapyCheck == null) {
      return;
    }

    let additionalOffices = document.querySelectorAll('.office-fields.additional-office');
    let firstOffice = document.querySelector('.office-fields.first-office');
    let firstOfficeInputs = firstOffice.querySelectorAll('.non-virtual-fields input, .non-virtual-fields select');
    let firstOfficeMapWrapper = firstOffice.querySelector('.office-map-wrapper');
    let firstOfficePhotosWrapper = firstOffice.querySelector('.office-photos-wrapper');
    let addAnotherOfficeBtn = document.querySelector('.add-new-office');

    onlineTherapyCheck.addEventListener('change', function(e) {
      if (e.target.checked) {
        addAnotherOfficeBtn.classList.add('d-none');
        firstOfficeMapWrapper.classList.add('d-none');
        firstOfficePhotosWrapper.classList.add('d-none');

        for (let nonVirtualInput of firstOfficeInputs) {
          nonVirtualInput.disabled = true;
        }

        for (let additionalOffice of additionalOffices) {
          additionalOffice.classList.add('d-none');
        }
      } else {
        addAnotherOfficeBtn.classList.remove('d-none');
        firstOfficeMapWrapper.classList.remove('d-none');
        firstOfficePhotosWrapper.classList.remove('d-none');

        for (let nonVirtualInput of firstOfficeInputs) {
          nonVirtualInput.disabled = false;
        }

        for (let additionalOffice of additionalOffices) {
          additionalOffice.classList.remove('d-none');
        }
      }
    });
  }
}
