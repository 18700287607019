import moment from 'moment';
import Turbolinks from 'turbolinks';
import {easepick} from '@easepick/bundle';
import {KbdPlugin} from '@easepick/kbd-plugin';
import {RangePlugin} from '@easepick/range-plugin';
import {PresetPlugin} from '@easepick/preset-plugin';
import {AmpPlugin} from '@easepick/amp-plugin';
import cssPicker from '!!raw-loader!@easepick/core/dist/index.css';
import cssKbd from '!!raw-loader!@easepick/kbd-plugin/dist/index.css';
import cssRange from '!!raw-loader!@easepick/range-plugin/dist/index.css';
import cssPreset from '!!raw-loader!@easepick/preset-plugin/dist/index.css';
import easePickOverrides from '!!raw-loader!../../../packs/easepick/overrides.css';
window.Tablesort = require('tablesort');
require('tablesort/src/sorts/tablesort.number');

export default class Analytics {
  constructor() {
    this.initializeDateRangePicker();
    this.initializeTablesort();
    this.initializeListMore();
    this.fixProvidersTablePagination();
  }

  initializeDateRangePicker() {
    const el = $('.dates-range-picker')[0];

    new easepick.create({
      element: el,
      css: cssPicker + cssKbd + cssRange + cssPreset + easePickOverrides,
      calendars: 2,
      grid: 2,
      autoApply: false,
      startDate: moment(el.dataset.startDate),
      endDate: moment(el.dataset.endDate),
      plugins: [KbdPlugin,RangePlugin, PresetPlugin, AmpPlugin],
      PresetPlugin: {
        position: 'left',
      },
      AmpPlugin: {
        resetButton: true,
      },
      KbdPlugin: {
        // html option just to remove the calendar icon that shows up by default
        html: '<span></span>',
        unitIndex: 1,
        dayIndex: 1,
      },
      setup(picker) {
        picker.on('select', (e) => {
          const { start, end } = e.detail;
          Turbolinks.visit(`/analytics?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`);
        });

        picker.on('clear', () => {
          Turbolinks.visit('/analytics');
        });
      },
    });
  }

  initializeTablesort() {
    const $table = document.querySelector('.community-providers .table');

    new window.Tablesort($table);
  }

  initializeListMore() {
    let listMoreLinks = document.querySelectorAll('.list-more');

    for (let listMoreLink of listMoreLinks) {
      listMoreLink.addEventListener('click', (e) => {
        e.preventDefault();
        let listContainer = document.querySelector(e.target.dataset.target);

        listContainer.style.height = 'auto';
        listMoreLink.parentNode.removeChild(listMoreLink);
      });
    }
  }

  fixProvidersTablePagination() {
    document.querySelectorAll('[data-js-community-providers-table]').forEach((el) => {
      el.setAttribute('id', 'community-providers-table');
      if (window.location.hash == '#community-providers-table')
        setTimeout(() => {
          el.scrollIntoView();
        }, 222);

      el.querySelectorAll('[data-js-pagination] a').forEach((pagLink) => {
        if (!pagLink.href) return;
        pagLink.href = `${pagLink.href}#community-providers-table`;
      });
    });
  }
}
