import * as chosen from 'chosen-js'; // eslint-disable-line no-unused-vars

export default class ShareReferrals {
  constructor() {
    this.initializeNotifyProvidersCheckHandler();
    this.initializeAddAMessageButton();
    this.initializePreviewProviderEmail();
    this.initializeUniversitySelect();
  }

  initializeNotifyProvidersCheckHandler() {
    let notifyProvidersCheck = document.querySelector('.shared-referrals-body input[type="checkbox"]');
    let notifyProvidersWrapper = document.querySelector('.shared-referrals-body .notify-providers-wrapper');

    notifyProvidersCheck.addEventListener('change', function(e) {
      if (e.target.checked) {
        notifyProvidersWrapper.classList.remove('d-none');
      } else {
        notifyProvidersWrapper.classList.add('d-none');
      }
    });
  }

  initializeAddAMessageButton() {
    let addAMessageBtn = document.querySelector('.notify-providers-wrapper .add-message-to-provider');
    let customProviderMessageWrapper = document.querySelector('.notify-providers-wrapper .custom-provider-message-wrapper');
    let customProviderMessageTextarea = customProviderMessageWrapper.querySelector('textarea');
    let originalButtonText = addAMessageBtn.textContent;

    addAMessageBtn.addEventListener('click', () => {
      if (customProviderMessageWrapper.classList.contains('d-none')) {
        addAMessageBtn.textContent = 'Remove Message';
        customProviderMessageTextarea.disabled = false;
        customProviderMessageWrapper.classList.remove('d-none');
      } else {
        addAMessageBtn.textContent = originalButtonText;
        customProviderMessageTextarea.disabled = true;
        customProviderMessageTextarea.value = null;
        customProviderMessageWrapper.classList.add('d-none');
      }
    });
  }

  initializePreviewProviderEmail() {
    let customProviderMessageWrapper = document.querySelector('.notify-providers-wrapper .custom-provider-message-wrapper');
    let customProviderMessageTextarea = customProviderMessageWrapper.querySelector('textarea');
    let customMessageContainer = document.querySelector('#preview-email-modal .modal-body table tr.custom-message');
    let customMessageContent = customMessageContainer?.querySelector('.custom-message-content');

    $('#preview-email-modal').on('show.bs.modal', () => {
      if (customProviderMessageTextarea.value) {
        customMessageContainer.classList.remove('d-none');
        customMessageContent.textContent = customProviderMessageTextarea.value;
      } else {
        customMessageContainer.classList.add('d-none');
        customMessageContent.textContent = '';
      }
    });
  }

  initializeUniversitySelect() {
    let universitySelect = $('.university-input');
    universitySelect.chosen();

    let chosenSearchInputs = $('.chosen-search-input');
    chosenSearchInputs.attr('aria-label', 'Search');
  }
}
