import ImageOrientationReseter from './image_orientation_reseter';

export default class OfficePhotosUploader {
  constructor() {
    let sampleDropzone = document.querySelector('.office-photos-uploader');

    if (sampleDropzone) {
      this.originalDropzoneBackgroundImage = sampleDropzone.style['background-image'];
    }

    this.initializeUploaders();
  }

  initializeUploaders() {
    document.addEventListener('change', function(evt) {
      if (evt.target && evt.target.classList.contains('office-photos-input')) {
        let element = evt.target;
        let dropzone = document.querySelector(`#${element.dataset.target} .office-photos-uploader`);
        let files = element.files;

        this.removeExistingPreviews(dropzone);

        if (files.length > 0) {
          this.updateDropzoneStyling(dropzone);
          this.createPreviews(files, dropzone);
        } else {
          this.resetDropzoneStyling(dropzone);
        }
      }
    }.bind(this));
  }

  updateDropzoneStyling(dropzone) {
    let messages = dropzone.querySelector('.message');

    dropzone.style['background-image'] = 'none';
    dropzone.style['align-content'] = 'start';
    dropzone.classList.add('row');
    messages.classList.add('d-none');
  }

  resetDropzoneStyling(dropzone) {
    let messages = dropzone.querySelector('.message');

    dropzone.style['background-image'] = this.originalDropzoneBackgroundImage;
    dropzone.style.removeProperty('align-content');
    dropzone.classList.remove('row');
    messages.classList.remove('d-none');
  }

  removeExistingPreviews(dropzone) {
    let existingPreviews = dropzone.querySelectorAll('.image-preview');
    if (existingPreviews.length == 0) { return; }

    [].forEach.call(existingPreviews, function(preview) {
      preview.parentNode.removeChild(preview);
    });
  }

  createPreviews(files, dropzone) {
    [].forEach.call(files, function(file) {
      let imgReader = new FileReader();

      ImageOrientationReseter.getOrientation(file, function(orientation) {
        imgReader.onloadend = function() {
          if (orientation > 0) {
            ImageOrientationReseter.call(imgReader.result, orientation, function(fixedImage) {
              this.createPreview(fixedImage, dropzone);
            }.bind(this));
          } else {
            this.createPreview(imgReader.result, dropzone);
          }
        }.bind(this);
      }.bind(this));

      imgReader.readAsDataURL(file);
    }.bind(this));
  }

  createPreview(imgData, dropzone) {
    dropzone.appendChild(this.previewImageTemplate(imgData));
  }

  previewImageTemplate(src) {
    let imageWrapper = document.createElement('div');
    imageWrapper.classList.add('image-preview');

    imageWrapper.innerHTML = `<img src="${src}" />`;

    return imageWrapper;
  }
}
