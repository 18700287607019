import maskInput from 'vanilla-text-mask';
import intlTelInput from 'intl-tel-input';

export default class PhoneInput {
  static get DEFAULT_COUNTRY() {
    return document.body.dataset.pageCountry;
  }

  constructor(input = null) {
    if (input === null) {
      this.initializePhoneInputs();
    } else {
      this.initializePhoneInput(input);
    }
  }

  initializePhoneInputs() {
    document.querySelectorAll('.input-phone').forEach((tel) => {
      this.initializePhoneInput(tel);
    });
  }

  initializePhoneInput(input) {
    let phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    intlTelInput(input, {
      allowDropdown: false,
      hiddenInput: 'phone_number',
      utilsScript: '/utils/intl-tel-input/utils.js',
      onlyCountries: [PhoneInput.DEFAULT_COUNTRY],
      separateDialCode: true
    });

    maskInput({
      inputElement: input,
      mask: phoneMask,
      guide: false
    });
  }
}
