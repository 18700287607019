import Rails from 'rails-ujs';

export default class AnnouncementsHandler {
  constructor() {
    this.initializeAnnouncementClick();
  }

  initializeAnnouncementClick() {
    let announcementBanner = document.querySelector('.alert.alert-announcement');

    if (announcementBanner == null) {
      return null;
    }

    announcementBanner.addEventListener('click', function(evt) {
      if (evt.target.classList.contains('alert-announcement')) {
        window.location = evt.target.dataset.path;
      }
    });

    this.initializeCloseAnnouncementButton();
  }

  initializeCloseAnnouncementButton() {
    let body = document.body;

    $('.alert.alert-announcement').on('closed.bs.alert', function() {
      body.classList.remove('has-announcement');

      Rails.ajax({
        type: 'POST',
        url: '/dismiss-announcement'
      });
    });
  }
}
