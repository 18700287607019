export default class UserEventsTracker {
  constructor() {
    this.initializeTrackeableLinks();
  }

  initializeTrackeableLinks() {
    document.querySelectorAll('[data-tss-trck]').forEach((link) => {
      link.addEventListener('click', async (e) => {
        const headers = {
          'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]')?.getAttribute('content'),
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        await fetch('/trck', { method: 'POST', body: `body=${e.target.closest('a').dataset.request}`, headers });

        e.target.setAttribute('data-tss-tracked', true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        e.target.removeAttribute('data-tss-tracked');
      });
    });
  }
}
