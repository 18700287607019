import Analytics from './analytics';
import AnnouncementsHandler from './announcements_handler';
import ContactTherapistForm from './contact_therapist_form';
import CommunityMemberProfile from './community_member_profile';
import ErroredFormsHandler from './errored_form_handler';
import GroupTherapiesHandler from './group_therapies_handler';
import HomeVideo from './home_video';
import InsuranceChecker from './insurance_checker';
import LicensesHandler from './licenses_handler';
import ListUniversityOrganizationProviders from './list_university_organization_providers';
import LocationAutocompleter from './location_autocompleter.js.erb';
import MobileNavHandler from './mobile_nav_handler';
import ShareReferrals from './share_referrals';
import OfficesHandler from './offices_handler';
import PopoversHandler from './popovers_handler';
import PreferNotToSayHandler from './prefer_not_to_say_handler';
import PromoteMyPractice from './promote_my_practice';
import Reviews from './reviews';
import ResourcePhotoUploader from './resource_photo_uploader';
import SignUpForm from './sign_up_form';
import StudentDispositions from './student_dispositions';
import StudentProfile from './student_profile';
import TherapistForm from './therapist_form';
import TherapistProfile from './therapist_profile.js.erb';
import UserEventsTracker from './user_events_tracker.js';
import './readmore.js';

window.resourcePhotoUploaderLoaded = false;

export default class ComponentsLoader {
  constructor() {
    this.loadComponentByPath(/^\/group-profile\/.+$/, function () {
      new TherapistForm();
    });

    this.loadComponentByPath(/^\/group-profile\/about$/, function () {
      new GroupTherapiesHandler();
      new PreferNotToSayHandler('group_practice');
    });

    this.loadComponentByPath(/^\/group-profile\/offices$/, function () {
      new OfficesHandler();
    });

    this.loadComponentByPath(/^\/profile\/.+$/, function () {
      new TherapistForm();
    });

    this.loadComponentByPath(/^\/profile\/personal$/, function () {
      new PreferNotToSayHandler('therapist');
    });

    this.loadComponentByPath(/^\/profile\/offices$/, function () {
      new OfficesHandler();
    });

    this.loadComponentByPath(/^\/profile\/qualifications$/, function () {
      new GroupTherapiesHandler();
      new LicensesHandler();
    });

    this.loadComponentByPath(/^\/therapists\/\d+\/.+$/, function () {
      new TherapistProfile();
      new ContactTherapistForm();
    });

    this.loadComponentByPath(/^\/therapists\/\d+\/.+\/insurance-check$/, function () {
      new InsuranceChecker();
    });

    this.loadComponentByPath(/^\/insurance-eligibility-checks.*$/, function () {
      new InsuranceChecker();
    });

    this.loadComponentByPath(/^\/students\/profile$/, function () {
      new StudentProfile();
    });

    this.loadComponentByPath(/^\/community_members\/profile$/, function () {
      new CommunityMemberProfile();
    });

    this.loadComponentByPath(/^\/$/, function () {
      new HomeVideo();
    });

    this.loadComponentByPath(/^\/dispositions.*$/, function () {
      new StudentDispositions();
    });

    this.loadComponentByPath(/^\/signup|listpractice|community-member-signup+/, function () {
      new SignUpForm();
    });

    this.loadComponentByPath(/^\/reviews.*/, function () {
      new Reviews();
    });

    this.loadComponentByPath(/^\/university_organization_providers.*/, function () {
      new ListUniversityOrganizationProviders();
    });

    this.loadComponentByPath(/^\/referrals\/share.*/, function () {
      new ShareReferrals();
    });

    this.loadComponentByPath(/^\/analytics.*/, function () {
      new Analytics();
    });

    this.loadComponentByPath(/^\/university-resources.*/, function () {
      if (!window.resourcePhotoUploaderLoaded) {
        new ResourcePhotoUploader();
        window.resourcePhotoUploaderLoaded = true;
      }
    });

    this.loadComponentByPath(/^\/settings\/promoting.*/, function () {
      new PromoteMyPractice();
    });

    new MobileNavHandler();
    new PopoversHandler();
    new LocationAutocompleter();
    new ErroredFormsHandler();
    new AnnouncementsHandler();
    new UserEventsTracker();
  }

  loadComponentByPath(condition, loader) {
    if (window.location.pathname.match(condition)) {
      loader();
    }
  }
}
