export default class GroupTherapiesHandler {
  constructor() {
    this.initializeGroupTherapyCheckHandler();
    this.initializeGroupsRemover();
  }

  initializeGroupTherapyCheckHandler() {
    let groupTherapyCheck = document.querySelector('.group-modality-check');
    let groupTherapyWrapper = document.querySelector('.form-group.group-therapies');

    if (groupTherapyWrapper == null) {
      return;
    }

    let groupTherapyFields = groupTherapyWrapper.querySelectorAll('input, select, textarea');

    groupTherapyCheck.addEventListener('change', function(e) {
      if (e.target.checked) {
        groupTherapyWrapper.classList.remove('not-selected');

        for (let field of groupTherapyFields) {
          field.disabled = false;
        }
      } else {
        groupTherapyWrapper.classList.add('not-selected');

        for (let field of groupTherapyFields) {
          field.disabled = true;
        }
      }
    });

    $('.group-therapies').on('cocoon:after-insert', function() {
      for (let field of groupTherapyWrapper.querySelectorAll('input, select, textarea')) {
        field.disabled = false;
      }
    });
  }

  initializeGroupsRemover() {
    let formWrapper = document.querySelector('.profile-form-wrapper');

    formWrapper.addEventListener('click', function(evt) {
      let removeGroup = evt.target.closest('a.delete-group-therapy');

      if (removeGroup && removeGroup.classList.contains('remove-group-therapy')) {
        evt.preventDefault();

        let groupFields = removeGroup.parentNode;
        let groupFieldsParent = groupFields.parentNode;

        groupFieldsParent.removeChild(groupFields);
      }
    });
  }
}
